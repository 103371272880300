const posSystemUrl = "https://possystem-api-sandbox.fiskaltrust.eu";

export default class PosSystemService {
    static async SendViaEmailAsync(issueResponse: IssueResponse, email: string, cashboxId: string, accessToken: string) {
        try {
            const request: IssueUpdate = {
                Action: "send",
                Target: {
                    Scheme: "email",
                    Address: email
                }
            };
            const response = await fetch(`${posSystemUrl}/v2/issue/${issueResponse.ftQueueID}/${issueResponse.ftQueueItemID}`, {
                method: 'PUT',
                body: JSON.stringify(request),
                headers: { "Content-type": "application/json", "x-cashbox-id": cashboxId, "x-cashbox-accesstoken": accessToken },
            });
            console.log(response);
        } catch (error) {
            alert("Error while tryng to send email.");
        }
    }

    static async SendViaSmsAsync(issueResponse: IssueResponse, number: string, cashboxId: string, accessToken: string) {
        try {
            const request: IssueUpdate = {
                Action: "send",
                Target: {
                    Scheme: "sms",
                    Address: number
                }
            };
            const response = await fetch(`${posSystemUrl}/v2/issue/${issueResponse.ftQueueID}/${issueResponse.ftQueueItemID}`, {
                method: 'PUT',
                body: JSON.stringify(request),
                headers: { "Content-type": "application/json", "x-cashbox-id": cashboxId, "x-cashbox-accesstoken": accessToken },
            });
            console.log(response);

        } catch (error) {
            alert("Error while tryng to send sms.");
        }
    }

    static async AcceptReceiptAsync(issueResponse: IssueResponse, cashboxId: string, accessToken: string) {
        try {
            const request: IssueUpdate = {
                Action: "accept"
            };
            const response = await fetch(`${posSystemUrl}/v2/issue/${issueResponse.ftQueueID}/${issueResponse.ftQueueItemID}`, {
                method: 'PUT',
                body: JSON.stringify(request),
                headers: { "Content-type": "application/json", "x-cashbox-id": cashboxId, "x-cashbox-accesstoken": accessToken },
            });
            console.log(response);
        } catch (error) {
            alert("Error while tryng to send sms.");
        }
    }
}

export interface IssueResponse {
    ftQueueID: string;
    ftQueueItemID: string;
}

export interface IssueUpdate {
    Action: string;
    Target?: IssueUpdateTarget;
}

export interface IssueUpdateTarget {
    Scheme: string;
    Address: string;
}