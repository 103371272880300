import React, {  } from 'react';
import Footer from '../common/Footer';
import styled from 'styled-components';
import Clock from '../common/Clock';
import { ReceiptIssueStateUpdatedMessage } from '../../models/receipt/SubmittedPrintMessage';

type Props = {
    ReceiptIssueStateUpdatedMessage: ReceiptIssueStateUpdatedMessage;
}

const StrechedButton = styled.button`
    width: 100%;
    height: 90vh;
    background: transparent;
    color: gray;
    border: 0px solid transparent;
`;

const Hr = styled.hr`
border-top: 2px solid black;
margin-top: 0px;
margin-bottom: 0px;
`;

const ReceiptIssuedScreen: React.FC<Props> = (props) => {

    let text = "";
    if (props.ReceiptIssueStateUpdatedMessage) {
      if (props.ReceiptIssueStateUpdatedMessage.printer === "digital") {
        text = `Beleg wurde digital abgerufen am ${props.ReceiptIssueStateUpdatedMessage.printstate.PrintedAt}.`;
      }
      else if (props.ReceiptIssueStateUpdatedMessage.printer === "physical") {
        text = `Beleg wurde ausgedruckt am ${props.ReceiptIssueStateUpdatedMessage.printstate.PrintedAt}.`;
      }
      else if (props.ReceiptIssueStateUpdatedMessage.printer === "customeraccepted") {
        text = `Beleg wurde vom Kunden akzeptiert am ${props.ReceiptIssueStateUpdatedMessage.printstate.PrintedAt}.`;
      }
      else if (props.ReceiptIssueStateUpdatedMessage.printer === "giveaway") {
        text = `Beleg wurde vom Kunden per Give-Away abgeholt, am ${props.ReceiptIssueStateUpdatedMessage.printstate.PrintedAt}.`;
      }
      else {
        text = "Beleg wurde übermittelt.";
      }
    }

    return (
        <div style={{ paddingTop: "1vh" }}>
            <Clock />
            <Hr />
            <StrechedButton>
                <p className='text-center'>{text}</p>
                <Footer />
            </StrechedButton>
        </div>
    );
};

export default ReceiptIssuedScreen;